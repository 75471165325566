import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const LocalStorage = new Vuex.Store({
    plugins: [createPersistedState({
		paths: 'data',
		key: 'auth',
		storage: window.localStorage
	})],

    state: {
        userBrowserAuth: []
    },

    getters: {

        getUserBrowserAuth: (state) => (email) => {
            var alreadyAuthorized = state.userBrowserAuth.find((element) => element === email)
            return alreadyAuthorized
		},
    },

    mutations: {
        setUserBrowserAuth(state, email) {
            var alreadyAuthorized = state.userBrowserAuth.find((element) => element === email)
            if (!alreadyAuthorized) {
                state.userBrowserAuth.push(email)
            }
        },

        clearUserBrowserAuth(state) {
            state.userBrowserAuth = []
        }
    },

})

export default LocalStorage;